import React, { createContext, useRef, useState, useCallback } from 'react'
import { Dialog as DialogUI, DialogProps as DialogPropsUI } from '@mui/material'
import { CommonDialogProps, RenderDialog, OpenDialog } from 'common/types'

export const DialogContext = createContext<OpenDialog>((() => Promise.resolve()) as OpenDialog)

export type DialogProviderRef = {
  renderDialog: RenderDialog<any>
  dialogPropsUI?: Omit<DialogPropsUI, 'open' | 'onClose'>
} & Omit<CommonDialogProps<any>, 'open'>

export type DialogProviderProps = {
  children: React.ReactNode
}

const DialogProvider = ({ children }: DialogProviderProps) => {
  const ref = useRef<DialogProviderRef>({
    renderDialog: () => null,
    resolve: () => {},
    reject: () => {},
  })

  const [open, setOpen] = useState(false)

  const openDialog = useCallback(
    <T = void,>(renderDialog: RenderDialog<T>, dialogPropsUI?: Omit<DialogPropsUI, 'open' | 'onClose'>) =>
      new Promise<T>((resolve, reject) => {
        if (open) throw new Error('Dialog is already opened!')

        const resolveAndClose = (value: T) => {
          resolve(value)
          setOpen(false)
        }

        const rejectAndClose = () => {
          reject()
          setOpen(false)
        }

        ref.current = {
          renderDialog,
          dialogPropsUI,
          resolve: resolveAndClose,
          reject: rejectAndClose,
        }

        setOpen(true)
      }),
    [open],
  )

  return (
    <DialogContext.Provider value={openDialog}>
      {children}
      <DialogUI
        fullWidth
        maxWidth="md"
        scroll="body"
        {...ref.current.dialogPropsUI}
        open={open}
        onClose={ref.current.reject}
      >
        {ref.current.renderDialog({ open, resolve: ref.current.resolve, reject: ref.current.reject })}
      </DialogUI>
    </DialogContext.Provider>
  )
}

export default DialogProvider
